.grid {
    display: grid;
    /* color: black; */
    gap: 3rem 1rem;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    padding-bottom: 100px;
}

.MuiCardActions-root {
    position: absolute;
    bottom: 0;
}

.project-card {
    height: 100%;
    position: relative;
}

.project-card.hidden {
    opacity: 0;
    transition: 300ms ease-in-out;
}

.project-card.active {
    opacity: 1;
    transition: 400ms ease-in-out;
    pointer-events: all;
}

.MuiButton-contained {
    cursor: default;
    pointer-events: none;
    color: white !important;
    background-color: black !important;
}

.project-card:hover {
    transform: scale(1.02);
    box-shadow: 0 0 20px 2px white;
    background: rgba(0,0,0,0);
}
