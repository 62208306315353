.wrapper {
    position: relative;
    left: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1px;
}

.clickbox {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    height: 100%;
    width: 2500%;
    cursor: pointer;
}

.el {
    position: absolute;
    opacity: 1;
    width: 2px;
    height: 26vh;
    margin-top: 70vh;
    transform-origin: 50% 100%;
    background: white;
}

.centered-component {
    transition: 200ms ease-in-out;
}

.left-component {
    position: relative;
    transition: 200ms ease-in-out;
    transform: translateX(-20vw);
}

.MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}

.skill-project:hover{
    box-shadow: 0 0 10px 1px rgba(140, 255, 201, 0.8);
    transform: scale(1.02);
    transition-duration: 300ms, 500ms;
    transition-property: transform, box-shadow;
}
