.col {
    padding: 20px 25px;
}

#home-grid {
    display: grid;
    /* color: black; */
    gap: 3rem 4rem;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    padding-bottom: 100px;
}

.home-cards:hover {
    box-shadow: 0 0 10px 1px rgba(255, 140, 140, 0.8);
    transform: scale(1.02);
    transition-duration: 300ms, 500ms;
    transition-property: transform, box-shadow;
}