.header-jumbo {
    padding: 2rem 2rem;
    background-size: 100%;
    background-color: rgba(19, 17, 17, 0) !important;
}

.header-text {
    text-align: center;
    margin: auto 10%;
    white-space: normal;
    font-size: 3.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 3.5;
    line-height: 1.2;
    color: white;
    border-radius: 5px;
}